import React from 'react'
import Seo from '../components/Seo'

export default function SeoSettings() {
  return <div>SeoTest</div>
}

export const Head = () => {
  return (
    <Seo
      title="Aurahomes"
      description="Ableaura Description"
      image="https://able-email-media.s3.amazonaws.com/aurahomes-media/aurahomes_logo.jpeg"
      twitter_image="https://able-email-media.s3.amazonaws.com/aurahomes-media/aurahomes_logo.jpeg"
    />
  )
}
